import { useContext, createContext, useState, useMemo } from 'react';
import useLocalStorage from '@/hooks/useLocalStorage';
import { useAuth } from '@/context/AuthContext';
import useActiveFeatureFlagsAlias from '@/hooks/useActiveFeatureFlagsAlias';

const PlatformContext = createContext();

export function usePlatform() {
  return useContext(PlatformContext);
}

export function PlatformProvider({ children }) {
  const provider = useProviderPlatform();

  return (
    <PlatformContext.Provider value={provider}>
      {children}
    </PlatformContext.Provider>
  );
}

function useProviderPlatform() {
  const { user } = useAuth();

  const [org, setOrg] = useLocalStorage('org');
  const [business, setBusiness] = useLocalStorage('business');
  const [context, setContext] = useLocalStorage('context');
  const [billing, setBilling] = useLocalStorage('billing');
  const [contextLocked, setContextLocked] = useState(false);

  const { featureFlags, isLoading: isLoadingFFlags } =
    useActiveFeatureFlagsAlias({ business });

  const initialOrg = useMemo(() => {
    let data = user?.user_accesses.find((o) => o.id === org?.id);
    if (!data) {
      data = user?.user_accesses[0];
    }
    return data;
  }, [user, org]);

  const initialBusiness = useMemo(() => {
    let data = initialOrg?.businesses.find((b) => b.id === business?.id);
    if (!data && initialOrg?.businesses?.length) {
      data = initialOrg?.businesses[0];
    }
    return data;
  }, [initialOrg, business]);

  const initialContext = useMemo(() => {
    let data = initialBusiness?.contexts.find((c) => c.id === context?.id);

    if (data) {
      return data;
    }

    if (initialBusiness?.contexts?.length > 1) {
      const main = initialBusiness?.contexts.find((c) => c.is_default);
      if (main) {
        return main;
      }
    }

    return initialBusiness?.contexts?.[0];
  }, [initialBusiness, context]);

  const initialBilling = useMemo(() => {
    let data = initialBusiness?.data_connectors.find((c) => {
      return (
        c.id === billing?.id &&
        checkProviderKind(billing.kind, initialContext?.provider)
      );
    });
    if (!data && initialBusiness?.data_connectors?.length) {
      data = initialBusiness?.data_connectors?.find((d) =>
        checkProviderKind(d.kind, initialContext?.provider)
      );
    }
    return data;
  }, [initialBusiness, initialContext, billing]);

  if (!!initialOrg && initialOrg !== org) {
    setOrg(initialOrg);
  }

  if (!!initialBusiness && initialBusiness !== business) {
    setBusiness(initialBusiness);
  }

  if (!!initialContext && initialContext !== context) {
    setContext(initialContext);
  }

  if (!!initialBilling && initialBilling !== billing) {
    setBilling(initialBilling);
  }

  function handleSetOrg(newOrg) {
    setOrg(newOrg);
  }

  const isOrgMsp = useMemo(() => {
    return org?.kind === 'MSP';
  }, [org]);

  function handleSetBusiness(newBusiness) {
    setBusiness(newBusiness);
    setContext(null);
    setBilling(null);
  }

  function handleSetContext(newContext) {
    setContext(newContext);
    setBilling(null);
  }

  function checkProviderKind(kind, provider) {
    if (kind === `${provider}-billing`) {
      return true;
    }

    return provider === 'focus' && kind === 'focus';
  }

  return {
    org,
    setOrg: handleSetOrg,
    business,
    setBusiness: handleSetBusiness,
    context,
    setContext: handleSetContext,
    contextLocked,
    setContextLocked,
    billing,
    setBilling,
    isOrgMsp,
    featureFlags,
    isLoadingFFlags,
  };
}
