import { useMemo } from 'react';
import useFetchFeatureFlags from '@/queries/useFetchFeatureFlags';
import {
  pierSuperAdminRole,
  engineeringTeamRole,
  useAuth,
} from '@/context/AuthContext';

function useAllCheckedFeatureFlagsAlias({ business }) {
  const { adminRole, user } = useAuth();

  const featureflagData = useFetchFeatureFlags({ enabled: !!user });

  const featureflags = useMemo(() => {
    if (!featureflagData.isSuccess) {
      return [];
    }

    const hasFullAccess = [pierSuperAdminRole, engineeringTeamRole].includes(
      adminRole
    );

    return featureflagData.data?.featureFlags?.reduce(
      (activateds, featureflag) => {
        if (
          !hasFullAccess &&
          featureflag.enabled &&
          !featureflag.businessess?.includes(business?.id)
        ) {
          activateds.push(featureflag.alias);
        }
        return activateds;
      },
      []
    );
  }, [
    business?.id,
    featureflagData.data?.featureFlags,
    featureflagData.isSuccess,
    adminRole,
  ]);

  return {
    featureFlags: new Set(featureflags),
    isLoading: featureflagData.isLoading,
  };
}

export default useAllCheckedFeatureFlagsAlias;
