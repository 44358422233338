import { lazy } from 'react';
import { Switch } from 'react-router-dom';
import PrivateRoute from '@/components/PrivateRoute';
import { useTranslation } from 'react-i18next';

const ViewConfig = lazy(() => import('./config'));
const Home = lazy(() => import('./home'));

function Routes() {
  const { t } = useTranslation();
  return (
    <Switch>
      <PrivateRoute
        exact
        path={'/lighthouse/executive-summary/reports'}
        title={t('lighthouse:executive.summary.views.title')}
        component={Home}
      />
      <PrivateRoute
        exact
        path={'/lighthouse/executive-summary/reports/:viewId/config'}
        title={t('lighthouse:executive.summary.settings.title')}
        component={ViewConfig}
      />
    </Switch>
  );
}

export default Routes;
