import { useMemo } from 'react';
import { createUseStyles } from 'react-jss';
import { useAuth } from '@/context/AuthContext';
import {
  SettingFilled,
  LogoutOutlined,
  WarningOutlined,
  RetweetOutlined,
  BugOutlined,
  BookOutlined,
} from '@ant-design/icons';
import { LanguageSelect } from '@/components';
import UserAvatar from '@/components/UserAvatar';
import { Menu, Space, Typography, Button, Popconfirm, Tag } from 'antd';
import { useTranslation } from 'react-i18next';

const useStyles = createUseStyles(() => ({
  content: {
    display: 'flex',
    flexDirection: 'column',
    minWidth: 320,
    fontSize: '1rem',
  },
  menu: {
    borderRight: 'None',
    borderTop: `1px solid rgba(0, 0, 0, 0.09)`,
    marginTop: 16,
    fontSize: '1rem',
    '& > .ant-menu-item': {
      padding: '2px 4px',
    },
  },
  footer: {
    padding: '16px 0 8px 0',
    borderTop: `1px solid rgba(0, 0, 0, 0.09)`,
    '& > .ant-btn-text': {
      border: `1px solid rgba(0, 0, 0, 0.09)`,
      fontSize: '1rem',
      height: 40,
      borderRadius: 4,
    },
  },
}));

function Content({
  onClose,
  onOpenReport,
  onFlushCache,
  onDocumentation,
  onChangeOrg,
  onSettings,
}) {
  const classes = useStyles();
  const { user, isSuperAdmin, isPiercloudStaff, logout } = useAuth();
  const { t } = useTranslation();

  const isAdmin = useMemo(() => {
    return isSuperAdmin() || isPiercloudStaff();
  }, [isSuperAdmin, isPiercloudStaff]);

  const menuItems = useMemo(() => {
    const items = [];

    if (user?.user_accesses?.length > 1) {
      items.push({
        key: 'change_org',
        label: t('common.change.org'),
        icon: <RetweetOutlined style={{ fontSize: '1.2rem' }} />,
      });
    }

    items.push({
      key: 'documentation',
      label: t('common.documentation'),
      icon: <BookOutlined style={{ fontSize: '1.2rem' }} />,
    });

    items.push({
      key: 'settings',
      label: t('common.settings'),
      icon: <SettingFilled style={{ fontSize: '1.2rem' }} />,
    });

    if (!!isAdmin) {
      items.push({
        type: 'divider',
      });

      items.push({
        key: 'report_problem',
        label: t('issue.report.problem'),
        icon: <BugOutlined style={{ fontSize: '1.2rem' }} />,
      });

      items.push({
        key: 'clear_cache',
        label: t('user.clear.cache'),
        icon: <WarningOutlined style={{ fontSize: '1.2rem' }} />,
        danger: true,
      });
    }

    return items;
  }, [isAdmin, user, t]);

  const handleMenuClick = ({ key }) => {
    const handles = Object.freeze({
      change_org: onChangeOrg,
      settings: onSettings,
      report_problem: onOpenReport,
      clear_cache: onFlushCache,
      documentation: onDocumentation,
    });

    const action = handles[key];
    if (!!action) {
      action();
      onClose();
    }
  };

  return (
    <div className={classes.content}>
      <Space size={24} align="center">
        <UserAvatar size={56} />
        <Space direction="vertical" size={2}>
          <Space size={8} align="center">
            <Typography.Text strong>{user?.displayName}</Typography.Text>
            {!!isAdmin && <Tag color="orange">{t('user.admin')}</Tag>}
          </Space>

          <Typography.Text type="secondary" style={{ fontSize: 14 }}>
            {user?.email}
          </Typography.Text>
        </Space>
        <LanguageSelect />
      </Space>

      <Menu
        items={menuItems}
        className={classes.menu}
        selectable={false}
        onClick={handleMenuClick}
      />

      <div className={classes.footer}>
        <Popconfirm
          placement="left"
          title={t('user.confirm.logout.title')}
          onConfirm={() => logout()}
          okText={t('user.confirm.logout')}
          cancelText={t('common.no')}
        >
          <Button
            type="primary"
            icon={<LogoutOutlined />}
            block
            style={{ fontWeight: 600 }}
          >
            {t('user.logout')}
          </Button>
        </Popconfirm>
      </div>
    </div>
  );
}

export default Content;
