import { useMemo, useEffect, cloneElement } from 'react';
import { Menu } from 'antd';
import { useLocation, useHistory } from 'react-router-dom';
import { useAuth } from '@/context/AuthContext';
import { usePlatform } from '@/context/PlatformContext';
import { useTranslation } from 'react-i18next';
import mapFromObject from '@/common/mapFromObject';

import ccaMenuItems from '@/modules/cca/siderMenuItems';
import AutofixMenuItems from '@/modules/autofix/siderMenuItems';
import LighthouseMenuItems from '@/modules/lighthouse/siderMenuItems';
import SettingsMenuItems from '@/modules/settings/siderMenuItems';
import SpaceMenuItems from '@/modules/space/siderMenuItems';
import SpotMenuItems from '@/modules/spot/siderMenuItems';
import SpManagerMenuItems from '@/modules/spManager/siderMenuItems';

function useScript(product) {
  const location = useLocation();
  const history = useHistory();
  const { t } = useTranslation();

  const { adminRole, collapsed } = useAuth();
  const { setContextLocked, context, isOrgMsp, featureFlags, isLoadingFFlags } =
    usePlatform();

  const pages = useMemo(() => {
    const payload = {
      t,
      isOrgMsp,
      adminRole,
      featureFlags,
      provider: context?.provider,
      collapsed,
    };

    const pagesMap = mapFromObject({
      lighthouse: LighthouseMenuItems(payload),
      cca: ccaMenuItems(payload),
      autofix: AutofixMenuItems(payload),
      space: SpaceMenuItems(payload),
      settings: SettingsMenuItems(payload),
      spot: SpotMenuItems(payload),
      'sp-manager': SpManagerMenuItems(payload),
    });

    return pagesMap.get(product);
  }, [
    t,
    adminRole,
    featureFlags,
    context?.provider,
    isOrgMsp,
    product,
    collapsed,
  ]);

  const page = useMemo(() => {
    const path = `${location.pathname}${location.search}`;
    let page = pages?.find(({ key }) => path === `/${key}`);

    if (!page) {
      return pages?.find(({ key }) => path.includes(key));
    }

    return page;
  }, [location, pages]);

  const pageSelected = useMemo(() => {
    const path = `${location.pathname}${location.search}`;

    function getPage(page = {}, path, parent = false) {
      if (`/${page.key}` === path) {
        return page;
      }
      if (parent && !page?.children && path.startsWith(`/${page.key}`)) {
        return page;
      }

      for (const child of page.children || []) {
        const found = getPage(child, path, parent);
        if (found) {
          return found;
        }
      }
    }
    const selectedPage = getPage(page, path);
    if (selectedPage) {
      return selectedPage;
    }
    return getPage(page, path, true);
  }, [location.pathname, location.search, page]);

  const selected = useMemo(() => {
    return pageSelected?.key;
  }, [pageSelected]);

  const openedKeys = useMemo(() => {
    if (page?.children?.length && !collapsed) {
      let key = pageSelected?.key?.split('/').slice(0, -1).join('/');
      return [page.key, key];
    }

    return [];
  }, [page, collapsed]);

  useEffect(() => {
    setContextLocked(!!pageSelected?.global);

    return () => setContextLocked(false);
  }, [pageSelected, setContextLocked]);

  const onMenuClick = ({ key }) => {
    history.push(`/${key}`);
  };

  return {
    loading: isLoadingFFlags,
    selected,
    openedKeys,
    menuItems: pages,
    onMenuClick,
  };
}

function SiderMenu({ product }) {
  const { loading, selected, openedKeys, menuItems, onMenuClick } =
    useScript(product);

  if (loading) {
    return null;
  }

  return (
    <Menu
      mode="inline"
      theme="light"
      selectedKeys={selected}
      defaultOpenKeys={openedKeys}
      style={{
        width: 'calc(100% - 1px)',
        border: 'none',
        overflowY: 'auto',
        overflowX: 'hidden',
        height: ' calc(100vh - 64px)',
      }}
      items={menuItems.map((item) => ({
        ...item,
        icon: cloneElement(item.icon, { style: { fontSize: 14 } }),
      }))}
      onClick={onMenuClick}
    />
  );
}

export default SiderMenu;
