import { createContext, useContext, useEffect, useState } from 'react';
import { apiWithoutBaseUrl } from '@/services/lighthouse';
import useWindowDimensions from '@/hooks/useWindowDimensions';
import { useQueryClient } from 'react-query';
import tracking from '@/services/tracking';
import { auth as AuthEvent } from '@/consts/events';

const AUTO_COLLAPSE_WIDTH = 995;
const authContext = createContext();

export const pierSuperAdminRole = 'piercloud-super-admin';
export const pierStaffRole = 'piercloud-staff';
export const businessSuperAdminRole = 'business-super-admin';
export const orgSuperAdminRole = 'organization-super-admin';
export const engineeringTeamRole = 'engineering-team';
export const pierRoles = [
  pierStaffRole,
  engineeringTeamRole,
  pierSuperAdminRole,
];
export const orgSuperRoles = [businessSuperAdminRole, orgSuperAdminRole];

export function useAuth() {
  return useContext(authContext);
}

export function useProvideAuth() {
  const [user, setUser] = useState(undefined);
  const [loading, setLoading] = useState(true);
  const [collapsed, setCollapsed] = useState(
    () => JSON.parse(localStorage.getItem('collapsed')) || false
  );
  const { width } = useWindowDimensions();
  const queryClient = useQueryClient();

  useEffect(() => {
    localStorage.setItem('collapsed', collapsed);
  }, [collapsed]);

  useEffect(() => {
    if (width < AUTO_COLLAPSE_WIDTH && !collapsed) setCollapsed(true);
  }, [width, collapsed]);

  function toggleSiderMenu() {
    if (collapsed && width < AUTO_COLLAPSE_WIDTH) return;
    setCollapsed(!collapsed);
  }

  async function loginWithCode(code) {
    try {
      const { auth } = await apiWithoutBaseUrl.post('/auth/login', {
        code,
        redirect_url: process.env.REACT_APP_FRONTEND_URL,
      });
      await setPersistence({ ...auth });
      await getAndSetUser(true);
    } catch (error) {
      throw error;
    }
  }

  async function setPersistence(auth) {
    await localStorage.setItem('auth', JSON.stringify({ ...auth }));
  }

  async function logout() {
    await flushToken();
    localStorage.clear();
    queryClient.invalidateQueries();
    setUser(null);
  }

  async function flushToken() {
    try {
      await apiWithoutBaseUrl.post('/auth/logout');
    } catch (error) {
      console.error(error);
    }
  }

  function authenticated() {
    return user !== null;
  }

  function isPiercloudUser() {
    return user?.email?.includes('@piercloud.com');
  }

  function isOrganizationSuperAdmin() {
    const { scope } = getUserAuth();
    return scope?.includes(orgSuperAdminRole);
  }

  function isBusinessSuperAdmin() {
    const { scope } = getUserAuth();
    return scope?.includes(businessSuperAdminRole);
  }

  function isPiercloudStaff() {
    const { scope } = getUserAuth();
    return isPiercloudUser() && scope?.includes(pierStaffRole);
  }

  function isSuperAdmin() {
    const { scope } = getUserAuth();
    return isPiercloudUser() && scope?.includes(pierSuperAdminRole);
  }

  function isPiercloudTeam() {
    return isPiercloudStaff() || isSuperAdmin();
  }

  function getUserAuth() {
    const auth = localStorage.getItem('auth');
    if (auth) {
      const { access_token: accessToken, scope, uid } = JSON.parse(auth);
      return { accessToken, scope, uid };
    }
    return {};
  }

  function getHighestAdminRole() {
    const { scope } = getUserAuth();
    let highestRole = null;

    const roles = [...orgSuperRoles, ...pierRoles];
    for (const role of roles) {
      if (scope?.includes(role)) {
        highestRole = role;
      }
    }

    return highestRole;
  }

  function isEngineeringTeam() {
    const { scope } = getUserAuth();
    return scope?.includes(engineeringTeamRole);
  }

  async function getAndSetUser(setLoginEvent = false) {
    try {
      const { data } = await apiWithoutBaseUrl.get('/users/me');
      setUser(data);
      tracking.setUserData(data);

      if (setLoginEvent) {
        tracking.logEvent({
          event: AuthEvent.EVENT_LOGIN,
          user: data,
          org: data.org,
        });
      }
    } catch (error) {
      setUser(null);
    }
  }

  useEffect(() => {
    async function initialize() {
      const { uid } = getUserAuth();
      if (uid) {
        await getAndSetUser();
      } else {
        setUser(null);
      }
      setLoading(false);
    }

    initialize();
  }, []);

  return {
    user,
    loginWithCode,
    logout,
    authenticated,
    loading,
    collapsed,
    toggleSiderMenu,
    isOrganizationSuperAdmin,
    isBusinessSuperAdmin,
    isPiercloudStaff,
    isPiercloudTeam,
    isSuperAdmin,
    getAndSetUser,
    adminRole: getHighestAdminRole(),
    isEngineeringTeam: isEngineeringTeam(),
  };
}

export function AuthProvider({ children }) {
  const auth = useProvideAuth();
  return <authContext.Provider value={auth}>{children}</authContext.Provider>;
}
