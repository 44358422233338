import { Popover } from 'antd';
import Content from './Content';
import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useModal } from '@/context/ModalContext';
import { useTranslation } from 'react-i18next';
import UserAvatar from '@/components/UserAvatar';

function UserMenu({ preventRedirect = false, OnOpenReport }) {
  const history = useHistory();
  const { t, i18n } = useTranslation();
  const { openModal } = useModal();

  const [open, setOpen] = useState(false);

  const handleOpenChange = (visible) => {
    setOpen(visible);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleFlushCache = () => {
    openModal({ name: 'FLUSH_CACHE_MODAL' });
  };

  const handleChangeOrg = () => {
    const message = t('common.has.unsaved.changes');

    if (preventRedirect) {
      if (!window.confirm(message)) {
        return;
      }
    }

    history.push('/setup/select-orgs');
  };

  const handleSettings = () => {
    const message = t('common.has.unsaved.changes');

    if (preventRedirect) {
      if (!window.confirm(message)) {
        return;
      }
    }

    history.push('/settings/profile');
  };

  const handleDocumentation = () => {
    const lanquagesMap = {
      'pt-BR': 'portugues',
      'es-ES': 'espanol',
      'en-US': 'english',
    };
    const docUrl = `${process.env.REACT_APP_DOC_URL}-${
      lanquagesMap[i18n.resolvedLanguage]
    }`;
    window.open(docUrl, '_blank');
  };

  return (
    <Popover
      content={
        <Content
          onClose={handleClose}
          onOpenReport={OnOpenReport}
          onFlushCache={handleFlushCache}
          onChangeOrg={handleChangeOrg}
          onSettings={handleSettings}
          onDocumentation={handleDocumentation}
        />
      }
      trigger="click"
      placement="bottomRight"
      open={open}
      onOpenChange={handleOpenChange}
    >
      <UserAvatar size={40} style={{ cursor: 'pointer' }} />
    </Popover>
  );
}

export default UserMenu;
