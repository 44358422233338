import React, { lazy } from 'react';
import { PrivateRoute } from '@/components';

const SelectOrg = lazy(() => import('./selectOrg'));

function Routes() {
  return (
    <>
      <PrivateRoute
        path={`/setup/select-orgs`}
        title="Select organization"
        component={SelectOrg}
        renderSiderMenu={false}
        renderLayout={false}
      />
    </>
  );
}

export default Routes;
